import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, message, Button, Modal, Badge, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../../lib/auth";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import { timeStamp } from "../../utility/timestamp";
import POS from "../../assets/images/pos.svg";
import PurchaseGRN from "../../assets/images/purchasegrn.svg";
import CashMgmt from "../../assets/images/cashregister.svg";
import Reports from "../../assets/images/reports.svg";
import Stock from "../../assets/images/stock.svg";
import dashboardImg from "../../assets/images/dashboard.svg";
import POSLogo from "../../assets/images/cwPosLogo.png";
import sync from "../../assets/images/sync.svg";
import logoutIcon from "../../assets/images/logout.svg";
import helpIcon from "../../assets/images/help.svg";
import dashboardBG from "../../assets/images/dashboard_newbg.png";
import DayClose from "../../assets/images/day_close.svg";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/dashboard.json";
import "../style.css";

// KDS Modal Imports //
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import All from "../../assets/images/all.svg";
import ParkedOrder from "../../assets/images/parkedOrder.svg";
import InProgress from "../../assets/images/inProgress.svg";
import Pending from "../../assets/images/pending.svg";
import Prepared from "../../assets/images/prepared.svg";
import Completed from "../../assets/images/completed.svg";
import TimerIcon from "../../assets/images/timer-icon.svg";
import MobileIcon from "../../assets/images/mobileIcon.svg";
import POSIcon from "../../assets/images/pointofsale.svg";
import restPosData from "../../constants/restaurantPos.json";
import _ from "lodash";

const LoadingOutlinedSpin = <LoadingOutlined style={{ fontSize: 54 }} spin />;

const Dashboard = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const username = localStorage.getItem("username");
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const { setAuthTokens } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [workTypeList, setWorkTypeList] = useState([
    { workTypeName: "POS", workTypeImg: POS, key: "pos", access: "N" },
    // { workTypeName: "Settings", workTypeImg: Settings },
    // { workTypeName: "Dashboard", workTypeImg: dashboardImg },
    // { workTypeName: "Stock", workTypeImg: Stock },
    { workTypeName: "PurchaseGRN", workTypeImg: PurchaseGRN, key: "purchase", access: "N" },
    { workTypeName: "ST Receipt", workTypeImg: Reports, key: "stReceipt", access: "N" },
    { workTypeName: "Wastage Qty", workTypeImg: Stock, key: "wastage", access: "N" },
    // { workTypeName: "Delivery", workTypeImg: Delivery },
    { workTypeName: "Day Close", workTypeImg: DayClose, key: "dayEnd", access: "N" },
    { workTypeName: "Cash Management", workTypeImg: CashMgmt, key: "cashManagement", access: "N" },
    { workTypeName: "Price Update", workTypeImg: Reports, key: "priceUpdate", access: "N" },
    { workTypeName: "B2B Sales", workTypeImg: PurchaseGRN, key: "b2BSales", access: "N" },
    // { workTypeName: "Reports", workTypeImg: Reports },
    { workTypeName: "KDS", workTypeImg: dashboardImg, key: "kds_print", access: "N" },
  ]);

  useEffect(() => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      db.tillEvents
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((events) => {
          if (events.length > 0) {
            for (let i = 0; i < events.length; i += 1) {
              const event = events[i];
              const tillCash = [];
              for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                const paymentObj = event.allPaymentsData[j];

                if (paymentObj.name.toLowerCase() === "cash") {
                  const openedCashEvents = [];
                  const closedCashEvents = [];

                  for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                    openedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.openingCashDenominations[x].count}
                      amount:${event.openingCashDenominations[x].amount}
                      denomination:${event.openingCashDenominations[x].value}
                    }`);
                  }
                  for (let z = 0; z < event.closingCashDenominations?.length; z += 1) {
                    closedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.closingCashDenominations[z].count}
                      amount:${event.closingCashDenominations[z].amount}
                      denomination:${event.closingCashDenominations[z].value}
                    }`);
                  }

                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(event.closingCash) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                    opening: ${event.openingCash}
                    sales: ${paymentObj.cashSaleAmount}
                    netsales: ${paymentObj.cashSaleAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${event.closingCash}
                    returns: 0
                    iscash: 1
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.openingCash}
                        expected: 0
                        diff: 0
                        transactionCount: 0
                        type: "Open"
                        description: ""
                        cashEventDetails: [${openedCashEvents}]
                      },
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.closingCash}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: [${closedCashEvents}]
                      }
                    ]
                  }`);
                } else {
                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    finFinancialAccount2Id: ${paymentObj.finDayCloseAccountId && parseFloat(paymentObj.amount) !== 0 ? `"${paymentObj.finDayCloseAccountId}"` : null}
                    opening: 0
                    sales: ${paymentObj.expectedAmount}
                    netsales: ${paymentObj.expectedAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${parseFloat(paymentObj.amount)}
                    returns: 0
                    iscash: 0
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${parseFloat(paymentObj.amount)}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: []
                      }
                    ]
                  }`);
                }
              }

              const paramsInput = {
                query: `mutation{
                  upsertTill(tillInfo:{
                    cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                    csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                    csclientID:"${tillData.tillAccess.csClientId}"
                    created:"${event.openingTime}"
                    createdby:"${tillData.tillAccess.csUserId}"
                    updated:"${event.openingTime}"
                    updatedby:"${tillData.tillAccess.csUserId}"
                    tillCash:[${tillCash}]
                  })
                  {    
                    status
                    message
                    cwrTillID
                          tillCash{
                      
                      cwrTillCashId
                      date
                      finPaymentmethodId
                      opening
                      sales
                      netsales
                      cashin
                      cashout
                      retainAmt
                      closing
                      returns
                      iscash
                      isclose
                      cashEvents{
                        cwrCashEventsID
                        amount
                        expected
                        diff
                        transactionCount
                        type
                        description
                        cashEventDetails{
                          cwrCashEventdetailsID
                          count
                          amount
                          denomination
                        }
                      }
                    }
                  }
                }`,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              }).then((response) => {
                const result = response.data.data.upsertTill;
                const status = result.status;
                if (status === "200") {
                  console.log(event.tillSessionId);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                } else {
                  console.log("Failed Till Session Sync ====> ", response);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                }
              });
            }
          }
        });
    }
  }, []);

  useEffect(() => {
    if (tillaccess) {
      Object.keys(tillaccess)?.map((tillAccessitem) => {
        let indexValue = _.findIndex(workTypeList, (item) => item.key === tillAccessitem);
        if (indexValue !== -1) {
          workTypeList[indexValue].access = tillaccess[tillAccessitem];
          setWorkTypeList([...workTypeList]);
        }
        return null;
      });
    }
  }, []);

  const [posConfig, setPosConfig] = useState({});
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("posConfig"))) {
      setIsLoading(true);
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
          getPOSConfig(application: "POS") {
            cwrPosConfigId
            application
            configJson
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      }).then((response) => {
        const posConfig = JSON.parse(response.data.data.getPOSConfig[0].configJson);
        localStorage.setItem("posConfig", JSON.stringify(posConfig));
        setPosConfig({ ...posConfig });
        setTimeout(() => setIsLoading(false), 700);
      });
    } else {
      setIsLoading(true);
      let posConfigFromStorage = JSON.parse(localStorage.getItem("posConfig"));
      setPosConfig({ ...posConfigFromStorage });
      setTimeout(() => setIsLoading(false), 700);
    }
  }, []);

  const handleLogout = () => {
    if (
      db.logConfiguration.toArray().then((fetched) => {
        fetched.map((item) => {
          item.logout.filter((item) => item.log === "Y").length > 0;
        });
      })
    ) {
      const timeMark = timeStamp();
      const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
      const logoutArray = [];
      logoutArray.push(`{
        type: "LGO",
        action: "LOG",
        description: "${timeMark}",
        date: "${currentDate}",
        time: "${timeMark}",
        orderNo: "",
        remarks: "",
      }`);
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
          upsertPOSLog(order: {
              tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
              userId: "${tillData.tillAccess.csUserId}" 
              bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
              lines: [${logoutArray}]
            }) {
            status   
            message
          }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });
    }
    setAuthTokens();
    localStorage.removeItem("tokens");
    localStorage.removeItem("posConfig");
  };

  const syncData = () => {
    history.push("/sync");
  };

  const insertTillOpenActivitity = () => {
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
        }
      });
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: timeStamp(),
        closingTime: null,
        openingCashDenominations: [
          {
            denomination: 1,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2,
            count: 0,
            amount: 0,
          },
          {
            denomination: 5,
            count: 0,
            amount: 0,
          },
          {
            denomination: 10,
            count: 0,
            amount: 0,
          },
          {
            denomination: 20,
            count: 0,
            amount: 0,
          },
          {
            denomination: 50,
            count: 0,
            amount: 0,
          },
          {
            denomination: 100,
            count: 0,
            amount: 0,
          },
          {
            denomination: 200,
            count: 0,
            amount: 0,
          },
          {
            denomination: 500,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2000,
            count: 0,
            amount: 0,
          },
        ],
        closingCashDenominations: null,
        openingCash: 0,
        closingCash: null,
        totalOpeningAmount: 0,
        totalClosingExpectedAmount: null,
        totalClosingTransactions: null,
        totalClosingAmount: null,
      };
      localStorage.setItem("tillSession", JSON.stringify(openTillData));
      history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    }
  };

  const selectActivity = (activity) => {
    if (activity === "POS") {
      if (tillaccess.pos === "Y") {
        if (posConfig.showTillOpening === "Y") {
          history.push("/open-till");
        } else {
          insertTillOpenActivitity();
        }
      } else {
        message.error("Access for POS Denied");
      }
    }
    if (activity === "Cash Management") {
      if (tillaccess.cashManagement === "Y") {
        const tillSession = localStorage.getItem("tillSession");
        if (tillSession) {
          if (JSON.parse(tillSession).tillStatus === "open") {
            const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
            const initialParkedList = storedParkedList ? storedParkedList : [];
            if (initialParkedList.length === 0) {
              if (posConfig.showDenominations === "Y") {
                history.push("/close-till");
              } else if (posConfig.showDenominations === "N") {
                history.push("/close-till-all");
              }
            } else {
              message.warning("Please close parked bills before closing till");
            }
          } else {
            if (posConfig.showTillOpening === "Y") {
              history.push("/open-till");
            } else {
              insertTillOpenActivitity();
            }
          }
        }
      } else {
        message.error("Access for Cash Management Denied");
      }
    }
    if (activity === "Day Close") {
      if (tillaccess.dayEnd === "Y") {
        history.push("/day-close");
      } else {
        message.error("Access for Day Close Denied");
      }
    }
    if (activity === "PurchaseGRN") {
      if (tillaccess.purchase === "Y") {
        history.push("/purchase-grn");
      } else {
        message.error("Access for Purchase GRN Denied");
      }
    }
    if (activity === "ST Receipt") {
      if (tillaccess.stReceipt === "Y") {
        history.push("/stock-receipt");
      } else {
        message.error("Access for Goods Receipt Denied");
      }
    }
    if (activity === "Wastage Qty") {
      if (tillaccess.wastage === "Y") {
        history.push("/wastage-qty");
      } else {
        message.error("Access for Wastage Qty Denied");
      }
    }
    if (activity === "Price Update") {
      if (tillaccess.priceUpdate === "Y") {
        history.push("/price-update");
      } else {
        message.error("Access for Price Update Denied");
      }
    }
    if (activity === "B2B Sales") {
      if (tillaccess.b2BSales === "Y") {
        history.push("/b2b-sales");
      } else {
        message.error("Access for B2B Sales Denied");
      }
    }
    if (activity === "KDS") {
      if (tillaccess.kds_print === "Y") {
        handleOrderStatus();
      } else {
        message.error("Access for KDS Sales Denied");
      }
    }
  };

  const sectionStyle = {
    width: data.dashboardStyles.sectionStyle.width,
    height: data.dashboardStyles.sectionStyle.height,
    backgroundImage: `url(${dashboardBG})`,
    backgroundPosition: data.dashboardStyles.sectionStyle.backgroundPosition,
    backgroundSize: data.dashboardStyles.sectionStyle.backgroundSize,
    backgroundRepeat: data.dashboardStyles.sectionStyle.backgroundRepeat,
    position: data.dashboardStyles.sectionStyle.position,
  };

  // Kitchen Display Modal Starts //

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  const orderStatus = [
    {
      title: "All",
      imgSrc: All,
      statusValue: "NE",
    },
    {
      title: "Parked",
      imgSrc: ParkedOrder,
      statusValue: "NE",
    },
    {
      title: "Pending",
      imgSrc: Pending,
      statusValue: "NE",
    },
    {
      title: "In Progress",
      imgSrc: InProgress,
      statusValue: "UP",
    },
    {
      title: "Prepared",
      imgSrc: Prepared,
      statusValue: "PR",
    },
    {
      title: "Completed",
      imgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOrderItemsModal, setDisplayOrderItemsModal] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(orderStatus[0]);

  const orderDetailsList = JSON.parse(localStorage.getItem("orderDetails"));
  const initialOrderList = orderDetailsList ? orderDetailsList : [];
  const [orderDetails, setOrderDetails] = useState(initialOrderList);

  const handleOrderStatus = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let obj = { ...item };
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salesPrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            obj["dateCreatedTime"] = newTime;
            let orderObj = {
              ...obj,
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: item.orderTime,
            };
            let findIndexOrder = _.findIndex(orderDetails, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              orderDetails.push(orderObj);
            } else {
              orderDetails[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
        getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
        cWCOrderId
        orderNo
        dateCreated
        customerId
        customerName
        mobileNo
        total
        noOfItems
        totalQty
        lineItems{
            productId
            name
            productCode
            quantity
            price
            subTotal
            addOnProducts{
                id
                name
                price
            }
        }
    }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { getNewOmsOrders } = response.data.data;
      // let newGetNewOmsOrders = getNewOmsOrders.filter((item, index) => (new Date(item.dateCreated).toLocaleDateString()) === (new Date().toLocaleDateString()));
      getNewOmsOrders?.map((item, index) => {
        let obj = { ...item };
        obj.status = "NE";
        obj.noOfItems = item.lineItems.length;
        obj.posOrders = "N";
        let time = new Date(item.dateCreated);
        let newTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        obj["dateCreatedTime"] = newTime;
        let findIndex = _.findIndex(orderDetails, ["cWCOrderId", item.cWCOrderId]);
        if (findIndex === -1) {
          orderDetails.push(obj);
        } else {
          orderDetails[findIndex] = obj;
        }
        return null;
      });
      localStorage.setItem("orderDetails", JSON.stringify([...orderDetails]));
      setOrderDetails([...orderDetails]);
    });
    setDisplayOrderItemsModal(true);
  };

  const handleOrderStatusSelection = (record) => {
    console.log("record", record);
    setSelectedOrderStatus(record);
  };

  const nextOrderStatus = (statusTitle) => {
    let newOrderStatus = [...orderStatus];
    let statusIndex = _.findIndex(newOrderStatus, (item) => (statusTitle ? item.title === statusTitle : item.title === selectedOrderStatus.title));
    if (statusIndex <= newOrderStatus.length - 1) setSelectedOrderStatus(newOrderStatus[statusIndex + 1]);
  };

  const setStatus = (status) => {
    let newStatus = "";
    switch (status) {
      case "NE":
        newStatus = "UP";
        break;
      case "UP":
        newStatus = "PR";
        break;
      case "PR":
        newStatus = "DE";
        break;
      default:
        newStatus = "NE";
    }
    return newStatus;
  };

  const handleStatusButton = (record, fieldName) => {
    if (record.status === "NE" || record.status === "UP" || record.status === "PR" || record.status === "DE") {
      handleInProgress(record, fieldName);
    } else {
      let newOrderDetails = [...orderDetails];
      let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
      newOrderDetails[recordIndex].status = setStatus(record.status);
      localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      setOrderDetails([...newOrderDetails]);
      nextOrderStatus();
    }
  };

  const handleInProgress = (record, fieldName) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "${record.status === "DE" ? "NE" : record.status}"
        })
        {
            status
            message
            orderStatus
        }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        let recordStatus = record.status;
        let newOrderDetails = [...orderDetails];
        let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOrderDetails[recordIndex].status = setStatus(record.status);
        localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
        setOrderDetails([...newOrderDetails]);
        nextOrderStatus(fieldName === "completeBtn" ? "Prepared" : recordStatus === "DE" ? "Parked" : "");
      }
    });
  };

  // Kitchen Display Modal Ends //

  const cardRef = useRef();

  const focusFirstElement = () => {
    setTimeout(() => {
      cardRef.current.focus();
    }, 100);
  };

  useEffect(() => {
    focusFirstElement();
  }, []);

  const workTypeKeyConfig = {
    79: "POS",
    71: "PurchaseGRN",
    82: "ST Receipt",
    87: "Wastage Qty",
    68: "Day Close",
    67: "Cash Management",
    85: "Price Update",
    66: "B2B Sales",
    75: "KDS",
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      console.log(e.key, keyCode);
      switch (keyCode) {
        case 83:
          syncData();
          break;
        case 76:
          handleLogout();
          break;
        case 72:
          handleHelpModal();
          break;
        default:
          selectActivity(workTypeKeyConfig[`${keyCode}`]);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const handleEnterSelectActivity = (e, activityVar) => {
    if (e.keyCode === 13) {
      selectActivity(activityVar);
    }
  };
  const handleEnterSyncData = (e) => {
    if (e.keyCode === 13) {
      syncData();
    }
  };
  const handleEnterLogout = (e) => {
    if (e.keyCode === 13) {
      handleLogout();
    }
  };

  // Keyboard Shortcuts Modal Starts //

  const [shortcutsListModalOpen, setShortcutsListModalOpen] = useState(false);

  const handleHelpModal = () => {
    setShortcutsListModalOpen(true);
  };

  // Keyboard Shortcuts Modal Ends //

  return (
    <Spin indicator={LoadingOutlinedSpin} spinning={isLoading} style={{ marginTop: "38vh" }}>
      <div>
        <div style={data.dashboardStyles.header}>
          <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
            <Col span={3}>
              <img src={POSLogo} style={data.dashboardStyles.header.logoIcon} alt="" />
            </Col>
            <Col span={6}>
              <label style={data.dashboardStyles.header.headLabel}>{tillData.tillAccess.csBunit.name}</label>
            </Col>
            <Col span={8}>
              <label style={data.dashboardStyles.dashboardInfo}>{tillData.tillAccess.cwrTill.till}</label>
            </Col>
            <Col span={7} style={data.dashboardStyles.right}>
              <label style={data.dashboardStyles.dashboardGreet}>Hi, {username} &emsp;</label>
            </Col>
          </Row>
        </div>
        <div style={sectionStyle}>
          <Row style={{ padding: data.dashboardStyles.padding }} gutter={[24, 24]}>
            {workTypeList &&
              workTypeList.length > 0 &&
              workTypeList.map(
                (item, index) =>
                  item.access === "Y" && (
                    <Col key={index} xs={{ span: 12 }} lg={{ span: 4 }}>
                      <Card
                        ref={index === 0 ? cardRef : null}
                        className="focusDashboardCard"
                        onKeyDown={(e) => handleEnterSelectActivity(e, item.workTypeName)}
                        tabIndex={0}
                        bodyStyle={{ padding: 5 }}
                        style={data.dashboardStyles.cardWorkType}
                        onClick={() => selectActivity(item.workTypeName)}
                      >
                        <Row justify="center">
                          <img style={data.dashboardStyles.imgWorkType} src={item.workTypeImg} alt="" />
                        </Row>
                        <Row justify="center">
                          <label style={data.dashboardStyles.dashboardLabel}>{item.workTypeName}</label>
                        </Row>
                      </Card>
                    </Col>
                  )
              )}
          </Row>
          <Row style={{ padding: "6% 5% 0%" }}>
            <Col xs={{ span: 12 }} lg={{ span: 16 }}>
              <img className="focusDashboardCard" tabIndex={0} onKeyDown={(e) => handleEnterSyncData(e)} style={data.dashboardStyles.sync} src={sync} alt="" onClick={syncData} />
              <img
                className="focusDashboardCard"
                tabIndex={0}
                onKeyDown={(e) => (e.keyCode === 13 ? handleHelpModal() : null)}
                style={data.dashboardStyles.logout}
                src={helpIcon}
                onClick={handleHelpModal}
                alt=""
              />
              <img
                className="focusDashboardCard"
                tabIndex={0}
                onKeyDown={(e) => handleEnterLogout(e)}
                style={data.dashboardStyles.logout}
                src={logoutIcon}
                onBlur={focusFirstElement}
                onClick={handleLogout}
                alt=""
              />
              <span tabIndex={0}></span>
            </Col>
          </Row>
        </div>

        {/* Kitchen Display Modal Starts */}
        <Modal
          closable={false}
          footer={null}
          bodyStyle={{ padding: 0, background: "#ECECEC" }}
          visible={displayOrderItemsModal}
          // onCancel={handleAddOnModalClose}
          style={{ top: 10 }}
          width="90%"
        >
          <Row>
            <Col span={2}>
              <Row>
                {orderStatus?.map((statusItem, index) => (
                  <Col
                    span={24}
                    style={
                      statusItem?.title === selectedOrderStatus?.title
                        ? restPosData.restaurantPos.content.orderStatusModal.selectedOrderStatusOptionsCol
                        : restPosData.restaurantPos.content.orderStatusModal.orderStatusOptionsCol
                    }
                    onClick={() => handleOrderStatusSelection(statusItem)}
                  >
                    <img src={statusItem?.imgSrc} alt={statusItem?.imgSrc} style={{ height: "46px" }} />
                    <h6 style={restPosData.restaurantPos.content.orderStatusModal.statusOptionName}>{statusItem?.title}</h6>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={22} style={{ padding: 10 }}>
              <Row>
                <Col span={12}>
                  <p style={restPosData.restaurantPos.content.orderStatusModal.custTitle}>{tillData.tillAccess.csBunit.name}</p>
                </Col>
                <Col span={11}>
                  <p style={restPosData.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                    <SyncOutlined onClick={() => handleOrderStatus()} style={restPosData.restaurantPos.content.selectedItemsDisplayModal.closeIcon} />
                  </p>
                </Col>
                <Col span={1}>
                  <p style={restPosData.restaurantPos.content.selectedItemsDisplayModal.closeIconP}>
                    <CloseOutlined
                      onClick={() => {
                        setDisplayOrderItemsModal(false);
                        // localStorage.setItem('orderDetails', "[]");
                      }}
                      style={restPosData.restaurantPos.content.selectedItemsDisplayModal.closeIcon}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Scrollbars autoHide className="orderCardsScroll">
                  <Row>
                    {_.filter(
                      selectedOrderStatus?.title === "Parked" ? [] : orderDetails,
                      (selecteditem) =>
                        selecteditem.status === selectedOrderStatus?.statusValue && new Date(selecteditem.dateCreated).toLocaleDateString() === new Date().toLocaleDateString()
                      // && (selectedOrderStatus.title !== "All" ? selecteditem.posOrders === "N" : true)
                    )?.map((item, index) => (
                      <Col span={6} style={{ paddingRight: 5, paddingBottom: 5 }}>
                        <Card bodyStyle={{ padding: 10 }} style={restPosData.restaurantPos.content.orderStatusModal.orderItemsCard}>
                          <Row style={{ marginBottom: 10 }}>
                            <Col span={15} style={{ paddingLeft: 5 }}>
                              <p style={restPosData.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.customerName || "Anonymous"}</p>
                              <p style={restPosData.restaurantPos.content.orderStatusModal.orderCustDetails}>#{item.orderNo}</p>
                              <div style={restPosData.restaurantPos.content.orderStatusModal.timerDiv}>
                                <img src={TimerIcon} alt="Timer Icon" style={{ paddingRight: 5, width: 15 }} />
                                <span style={restPosData.restaurantPos.content.orderStatusModal.orderTime}>00.02</span>
                              </div>
                            </Col>
                            <Col span={6} style={{ textAlign: "center" }}>
                              <p style={restPosData.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.dateCreatedTime}</p>
                              <p style={restPosData.restaurantPos.content.orderStatusModal.orderCustDetails}>{item.noOfItems} Items</p>
                            </Col>
                            <Col span={selectedOrderStatus.title === "All" ? 3 : 2} style={{ textAlign: "center" }}>
                              {item.posOrders === "N" ? (
                                <img src={MobileIcon} alt="Mobile Icon" style={restPosData.restaurantPos.content.orderStatusModal.mobileIcon} />
                              ) : (
                                <img src={POSIcon} alt="POS Icon" style={restPosData.restaurantPos.content.orderStatusModal.mobileIcon} />
                              )}
                            </Col>
                            {selectedOrderStatus.title === "All" ? (
                              <Col span={0} />
                            ) : (
                              <Col span={1} style={restPosData.restaurantPos.content.orderStatusModal.statusCol}>
                                <Badge
                                  color={
                                    item.status === "NE"
                                      ? "#EF8354"
                                      : item.status === "UP"
                                      ? "#D5BD1C"
                                      : item.status === "PR"
                                      ? "#21932A"
                                      : item.status === "DE"
                                      ? "#3ADFC9"
                                      : "#ffffff"
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                          <Scrollbars autoHide className="orderItemsScroll">
                            <div style={restPosData.restaurantPos.content.orderCart.thirdRow}>
                              {item?.lineItems?.map((productItem, index) => (
                                <div
                                  style={{
                                    padding: "5px 0px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Row>
                                    <Col span={1}>
                                      <p style={restPosData.restaurantPos.content.orderCart.productDetails}>{index + 1}</p>
                                    </Col>
                                    <Col span={14}>
                                      <p style={restPosData.restaurantPos.content.orderCart.productDetails}>{productItem.name}</p>
                                    </Col>
                                    <Col span={9}>
                                      <p style={restPosData.restaurantPos.content.orderCart.productDetails}>
                                        <Row>
                                          <Col span={10}>
                                          {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}{" "}
                                            {Number(_.sumBy(productItem?.addOnProducts, "price") || 0) + (item.posOrders === "Y" ? productItem.salePrice : productItem.price)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                          </Col>
                                          <Col span={5}>X</Col>
                                          <Col span={9}>
                                            <span style={restPosData.restaurantPos.content.orderCart.productQty}>
                                              <span style={restPosData.restaurantPos.content.orderCart.productDetails}>
                                                {item.posOrders === "Y" ? productItem.weight : productItem.quantity}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                      </p>
                                    </Col>
                                  </Row>
                                  {productItem?.addOnProducts?.map((subitem) => (
                                    <div style={restPosData.restaurantPos.content.orderCart.thirdRow}>
                                      <Row>
                                        <Col span={10} style={restPosData.restaurantPos.content.orderStatusModal.orderModifierCol}>
                                          <p style={restPosData.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                                        </Col>
                                        <Col
                                          span={14}
                                          // style={restPosData.restaurantPos.content.orderCart.amtTextAlign}
                                        >
                                          <p style={restPosData.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                            {/* ))} */}
                          </Scrollbars>
                          <Row>
                            <Col span={selectedOrderStatus.title === "Pending" ? 6 : 16} />
                            <Col
                              span={selectedOrderStatus.title === "Pending" ? 9 : 0}
                              style={{
                                display: selectedOrderStatus.title === "Pending" ? "block" : "none",
                                paddingRight: 8,
                              }}
                            >
                              <Button
                                size="medium"
                                style={restPosData.restaurantPos.content.orderStatusModal.statusBtn}
                                className="addButton"
                                onClick={() => handleStatusButton({ ...item, status: "PR" }, "completeBtn")}
                              >
                                <span style={restPosData.restaurantPos.content.orderStatusModal.statusBtn.label}>Complete</span>
                              </Button>
                            </Col>
                            <Col
                              span={selectedOrderStatus.title === "Pending" ? 9 : 8}
                              style={{
                                display: selectedOrderStatus.title === "All" ? "none" : "block",
                              }}
                            >
                              <Button
                                size="medium"
                                style={
                                  item.status === "PR" || item.status === "DE"
                                    ? restPosData.restaurantPos.content.orderStatusModal.statusBtnCompleted
                                    : restPosData.restaurantPos.content.orderStatusModal.statusBtn
                                }
                                className="addButton"
                                onClick={() => handleStatusButton(item)}
                              >
                                <span style={restPosData.restaurantPos.content.orderStatusModal.statusBtn.label}>
                                  {item.status === "NE"
                                    ? "Accept"
                                    : item.status === "UP"
                                    ? "Prepared"
                                    : item.status === "PR"
                                    ? "Complete"
                                    : item.status === "DE"
                                    ? "Recall"
                                    : "Accept"}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Scrollbars>
              </Row>
            </Col>
          </Row>
        </Modal>
        {/* Kitchen Display Modal Ends */}

        {/* Keyboard Shortcuts Modal Starts */}
        <Modal
          title={
            <Row>
              <Col span={12}>
                <p className="shortcutsModalTitle">Keyboard Shortcuts</p>
              </Col>
              <Col span={12}>
                <p style={{ float: "right", marginBottom: "0px" }}>
                  <CloseOutlined onClick={() => setShortcutsListModalOpen(false)} style={{ fontSize: 18, color: "#000000" }} />
                </p>
              </Col>
            </Row>
          }
          closable={false}
          onCancel={() => setShortcutsListModalOpen(false)}
          footer={null}
          bodyStyle={{ padding: 0 }}
          visible={shortcutsListModalOpen}
          // onCancel={handleAddOnModalClose}
          style={{ top: 50 }}
          width="90%"
        >
          <div style={{ padding: "10px 15px" }}>
            <Row>
              <Col span={8}>
                <p className="screenTitle">General Shortcuts</p>
                <ul className="shortcutUl">
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Esc
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Home</span>
                  </li>
                </ul>
                <p className="screenTitle">Home Screen</p>
                <ul className="shortcutUl">
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      H
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Keyboard Help</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      O
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to POS</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      G
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Purchase GRN</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      R
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to ST Receipt</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      W
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Wastage Type</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      D
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Day Close</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      C
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Cash Management</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      U
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Price Update</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      B
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to B2B Sales</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      S
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Sync</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      L
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Log out</span>
                  </li>
                </ul>
              </Col>
              <Col span={8} style={{ paddingLeft: 10 }}>
                <p className="screenTitle">POS Screen</p>
                <ul className="shortcutUl">
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      F4
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go to Products Search</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Tab
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">To Select Required Product</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Enter
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Add Product to Cart</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      C
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">To Select Product in Cart</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      I
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go To Quantity Update</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      +
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Increase Quantity</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      -
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Decrease Quantity</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Delete
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Remove Product</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      S
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Product Line Sales Rep Popup</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      R
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go To Sales Return Screen</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      P
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Payment Modal</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      O
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Order History Modal</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      B
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Parked Bill Modal</span>
                  </li>
                </ul>
              </Col>
              <Col span={8}>
                <ul className="shortcutUl">
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      L
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Save Cart Items</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      H
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">Go To Home Screen</span>
                  </li>
                  <li className="shortcutList">
                    <Button size="small" className="shortcutBtn">
                      Alt
                    </Button>{" "}
                    <Button size="small" className="shortcutBtn">
                      W
                    </Button>{" "}
                    &nbsp; <span className="shortcutForScrenName">To Get Weight in Product Weight Modal</span>
                  </li>
                </ul>
              </Col>
              {/* <Col span={6}></Col> */}
            </Row>
          </div>
        </Modal>
        {/* Keyboard Shortcuts Modal Ends */}
      </div>
    </Spin>
  );
};

export default Dashboard;
